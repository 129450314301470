import React from 'react';
import { Helmet } from 'react-helmet';

class ErrorSplash extends React.Component {

  static getStylesheet() {
    return `
      body {
        background-color: #f5f5f5;
      }
    `;
  }

  static getDerivedStateFromError(error) {
    return {
      error,
      hasError: true,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      error: {},
      hasError: false,
    };
  }

  render() {
    const { error, hasError } = this.state;

    if (!hasError) {
      return this.props.children;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Error Page &ndash; Sprint Boards
          </title>

          <meta name="robots" content="noindex" />

          <style type="text/css">
            {ErrorSplash.getStylesheet()}
          </style>
        </Helmet>

        <div className="container pt-5 pb-4">
          <div className="w-100 mb-4 text-center">
            <a href={process.env.REACT_APP_SITE_URL} className="text-center mb-4 p-2">
              <img src={`${process.env.PUBLIC_URL}/images/logo-inverse.png`} alt="Sprint Boards" width={170} />
            </a>
          </div>

          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 m-auto">
              <div className="card shadow-sm border-0 mb-4">
                {error.name === 'ChunkLoadError' && (
                  <div className="card-body text-center px-4 py-4">
                    <h2 className="h4 font-weight-normal mt-1 mb-3">
                      Application Updated
                    </h2>

                    <p className="h5 font-weight-light mb-4">
                      Please refresh to get the latest version.
                    </p>

                    <div className="w-100 mb-1">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => window.location = `${window.location.href.replace(/#.*$/, '').split('?')[0]}?refresh=${Date.now()}`}
                      >
                        Refresh Page
                      </button>
                    </div>
                  </div>
                )}

                {error.name !== 'ChunkLoadError' && (
                  <div className="card-body text-center px-4 py-4">
                    <h2 className="h4 font-weight-normal mt-1 mb-3">
                      Application Error
                    </h2>

                    <p className="h5 font-weight-light mb-4">
                      Sorry, something went wrong.
                    </p>

                    <div className="w-100 mb-1">
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => window.location = `${window.location.href.replace(/#.*$/, '').split('?')[0]}?refresh=${Date.now()}`}
                      >
                        Refresh Page
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <p className="small text-center">
                <a href={`${process.env.REACT_APP_SITE_URL}/support/create`} className="text-secondary">
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

}

export default ErrorSplash;
