import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon as FontAwesome } from '@fortawesome/react-fontawesome';

class PageLoading extends React.Component {

  static getStylesheet() {
    return `
      body {
        margin: 0;
        overflow: hidden;
        background-color: #fff;
      }
      
      div#loading {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 100vh;
      }
      
      div#loading > div > p {
        margin: 0;
        font-size: 30px;
        color: #6c757d;
      }
    `;
  }

  render() {
    const { timedOut, retry } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <style type="text/css">
            {PageLoading.getStylesheet()}
          </style>
        </Helmet>

        <div id="loading">
          <div>
            <p>
              <FontAwesome icon="circle-notch" spin />
            </p>

            {timedOut && (
              <p className="mt-3">
                <button onClick={retry} className="btn btn-sm btn-outline-secondary">
                  Reload Page
                </button>
              </p>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

}

export default PageLoading;
