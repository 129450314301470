import React from 'react';
import Axios from 'axios';
import Cookie from 'js-cookie';
import ReactGA from 'react-ga';
import Auth from './modules/Auth';
import Loadable from 'react-loadable';
import ScrollTop from './components/ScrollTop';
import PageLoading from './components/PageLoading';
import AuthRoute from './components/routes/AuthRoute';
import GuestRoute from './components/routes/GuestRoute';
import PublicRoute from './components/routes/PublicRoute';
import { Switch, withRouter } from 'react-router-dom';

const Home = Loadable({
  loader: () => Promise.all([
    import('./views/home/Home'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Contact = Loadable({
  loader: () => Promise.all([
    import('./views/Contact'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Login = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Login'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Register = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Register'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,
});

const Verify = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Verify'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Activate = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Activate'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Forgot = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Forgot'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Reset = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Reset'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Account = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Account'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Integrations = Loadable({
  loader: () => Promise.all([
    import('./views/auth/Integrations'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const ManageTeamMembers = Loadable({
  loader: () => Promise.all([
    import('./views/team-members/ManageTeamMembers'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const CreateBoard = Loadable({
  loader: () => Promise.all([
    import('./views/boards/CreateBoard'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const ManageBoards = Loadable({
  loader: () => Promise.all([
    import('./views/boards/ManageBoards'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const ViewBoard = Loadable({
  loader: () => Promise.all([
    import('./views/boards/ViewBoard'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const ActionsTracker = Loadable({
  loader: () => Promise.all([
    import('./views/ActionsTracker'),
    new Promise(resolve => setTimeout(resolve, 200)),
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Terms = Loadable({
  loader: () => Promise.all([
    import('./views/Terms'),
    new Promise(resolve => setTimeout(resolve, 200))
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const Releases = Loadable({
  loader: () => Promise.all([
    import('./views/Releases'),
    new Promise(resolve => setTimeout(resolve, 200))
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const HowItWorks = Loadable({
  loader: () => Promise.all([
    import('./views/about/HowItWorks'),
    new Promise(resolve => setTimeout(resolve, 200))
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

const PageNotFound = Loadable({
  loader: () => Promise.all([
    import('./views/PageNotFound'),
    new Promise(resolve => setTimeout(resolve, 200))
  ]).then(([component]) => component),

  loading: PageLoading,

  timeout: 5000,
});

class Pages extends React.Component {

  constructor(props) {
    super(props);

    this.showRefreshing = this.showRefreshing.bind(this);
    this.hideRefreshing = this.hideRefreshing.bind(this);

    this.state = {
      refreshing: false,
    };
  }

  static loadStripeCheckout() {
    const script = document.createElement('script');

    script.src = 'https://js.stripe.com/v3';

    document.body.appendChild(script);
  }

  componentDidMount() {
    this.setAxiosDefaults();

    this.loadGoogleAnalytics();

    Pages.loadStripeCheckout();
  }

  setAxiosDefaults() {
    Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    if (Cookie.get('api_token')) {
      Axios.defaults.headers.common.Authorization = `Bearer ${Cookie.get('api_token')}`;
    }

    Axios.interceptors.response.use(response => response, error => {
      if (typeof error.response !== typeof undefined) {
        const status = error.response.status;

        if (status === 401) {
          const { history } = this.props;

          Cookie.remove('api_token');

          history.push('/auth/login', { error: 'Your session has expired.' });
        } else if (status === 429) {
          setTimeout(() => {
            window.toastr.error('Too many requests. Wait a minute.');
          }, 3000);
        } else if (status === 403) {
          window.toastr.error('You are not authorised to do that.');
        } else if (status !== 422) {
          window.toastr.error('API request failed. Please try again.');
        }
      }

      return Promise.reject(error);
    });
  }

  loadGoogleAnalytics() {
    const { history, location } = this.props;

    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

    const setAnalytics = location => {
      ReactGA.set({
        page: location.pathname,
        title: location.pathname,
        anonymizeIp: true,
      });

      ReactGA.pageview(window.location.pathname + window.location.search);
    };

    history.listen(location => {
      setAnalytics(location);
    });

    setAnalytics(location);
  }

  showRefreshing() {
    this.setState({
      refreshing: true,
    });
  }

  hideRefreshing() {
    this.setState({
      refreshing: false,
    });
  }

  render() {
    return (
      <ScrollTop>
        <Switch>
          <PublicRoute
            exact
            path="/"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Home}
          />

          <PublicRoute
            exact
            path="/support/create"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Contact}
          />

          <GuestRoute
            exact
            path="/auth/login"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Login}
          />

          <GuestRoute
            exact
            path="/auth/register"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Register}
          />

          <GuestRoute
            exact
            path="/auth/verify"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Verify}
          />

          <GuestRoute
            exact
            path="/auth/activate"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Activate}
          />

          <PublicRoute
            exact
            path="/auth/forgot"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Forgot}
          />

          <PublicRoute
            exact
            path="/auth/reset"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Reset}
          />

          <AuthRoute
            exact
            path="/auth/account"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Account}
          />

          <AuthRoute
            exact
            path="/auth/integrations/:provider?"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Integrations}
          />

          <AuthRoute
            exact
            path="/team-members"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={ManageTeamMembers}
          />

          <AuthRoute
            exact
            path="/boards"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={ManageBoards}
          />

          <AuthRoute
            exact
            path="/boards/create"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={CreateBoard}
            orElse={() => Auth.user() && Auth.user().is_admin_for_others}
          />

          <PublicRoute
            exact
            path="/boards/:id"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={ViewBoard}
          />

          <PublicRoute
            exact
            path="/actions-tracker"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={ActionsTracker}
          />

          <PublicRoute
            exact
            path="/terms"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Terms}
          />

          <PublicRoute
            exact
            path="/help/releases"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={Releases}
          />

          <PublicRoute
            exact
            path="/about/how-it-works"
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={HowItWorks}
          />

          <PublicRoute
            showRefreshing={this.showRefreshing}
            hideRefreshing={this.hideRefreshing}
            component={PageNotFound}
          />
        </Switch>
      </ScrollTop>
    );
  }

}

export default withRouter(Pages);
