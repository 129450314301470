import Cookie from 'js-cookie';
import Axios from 'axios';

class Auth {

  static user() {
    const user = Cookie.get('user');
    const token = Cookie.get('api_token');

    if (typeof token === typeof undefined || typeof user === typeof undefined) {
      return null;
    }

    return JSON.parse(user);
  }

  static id() {
    const user = Auth.user();

    if (user) {
      return user.id;
    }

    return null;
  }

  static refresh() {
    return new Promise((resolve, reject) => {
      if (Auth.user()) {
        Axios.get(`/users/${Auth.user().id}`).then(response => {
          const { user } = response.data;

          Cookie.set('user', user);

          resolve(response);
        }).catch(error => {
          reject(error);
        });
      } else {
        reject();
      }
    });
  }

  static clear() {
    Cookie.remove('user');
    Cookie.remove('api_token');
  }

}

export default Auth;
